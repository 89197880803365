import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ErrorIcon from "@material-ui/icons/Error";
import red from "@material-ui/core/colors/red";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const PROJECTS_QUERY = gql`
  {
    projects {
      id
      url
      subdomain
      name
    }
  }
`;

const useStyles = makeStyles({
  dialogTitle: {
    minWidth: 300
  }
});

function ProjectDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, ...other } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(value) {
    window.location = value;
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="project-dialog-title"
      {...other}
    >
      <DialogTitle className={classes.dialogTitle} id="project-dialog-title">
        Choose a project
      </DialogTitle>
      <div>
        <List>
          <Query query={PROJECTS_QUERY}>
            {({ loading, error, data, startPolling }) => {
              if (loading) return <ListItem>Loading...</ListItem>;
              if (error)
                return (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: red[300] }}>
                        <ErrorIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>{error.message}</ListItemText>
                  </ListItem>
                );
              return data.projects.map(({ name, url }) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(url)}
                  key={url}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText primary={name} />
                </ListItem>
              ));
            }}
          </Query>
        </List>
      </div>
    </Dialog>
  );
}

ProjectDialog.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.string
};

export default ProjectDialog;
