import { useState, useEffect } from "react";
import useInterval from "./useInterval";
import ms from "ms";
import { client } from '../ApolloProvider';

const AUTH_KEY = "crabgear-auth-token";

const getToken = () => {
  if (!localStorage) {
    return null;
  } else {
    const token = localStorage.getItem(AUTH_KEY);
    if (token && token.length) {
      if (tokenExpired(token)) {
        localStorage.removeItem(AUTH_KEY);
        dispatchEvent(null);
        return null;
      } else {
        return token;
      }
    } else {
      return null;
    }
  }
};

const getUserAttributes = () => {
  const token = getToken();
  if (token) {
    return JSON.parse(atob(token.split(".")[1])).user;
  } else {
    return null;
  }
}

const tokenExpired = token =>
  new Date(JSON.parse(atob(token.split(".")[1])).exp * 1000) < new Date();

// Events from token changes need to be synthetically dispatched because events are not fired for
// changes to localStorage that originate from the same window. Events *will* be fired from changes
// on other tabs.
const dispatchEvent = value => {
  var event = document.createEvent("Event");
  event.initEvent("storage", true, true);
  event.key = AUTH_KEY;
  event.storageArea = localStorage;
  event.newValue = value;
  window.dispatchEvent(event);
};

const useAuthToken = () => {
  const [value, setValue] = useState(getToken());
  
  // listen for changes
  const listener = e => {
    if (e.storageArea === localStorage && e.key === AUTH_KEY) {
      setValue(e.newValue);
    }
  };

  useEffect(
    () => {
      window.addEventListener("storage", listener);
      return () => {
        window.removeEventListener("storage", listener);
      }
    },
    [AUTH_KEY]
  );

  // periodically check expiration
  useInterval(getToken, ms("1m"));

  const setToken = value => {
    if (value === null) {
      throw new Error("Cannot set token to null. Use clearToken.");
    }
    if (localStorage) {
      localStorage.setItem(AUTH_KEY, value);
      client.resetStore();
      dispatchEvent(value);
    } else {
      setValue(value);
    }
  };

  const clearToken = () => {
    if (localStorage) {
      localStorage.removeItem(AUTH_KEY);
      client.resetStore();
      dispatchEvent(null);
    } else {
      setValue(null);
    }
  };

  return [value, setToken, clearToken];
};

export default useAuthToken;
export {
  getToken,
  getUserAttributes
};
