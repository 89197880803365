import React from "react";
import AppStoreLogo from "../../AppStore.svg";
import { makeStyles } from "@material-ui/styles";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import GooglePlayBadge from '../../google-play-badge.png';
import ProjectDialog from "./ProjectDialog";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 400,
    textAlign: 'left',
    marginBottom: 20,
  },
  container: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    textAlign: 'center',
    background: `linear-gradient(#5ba3e1, #4b5fcd)`,
    width: "100vw",
    height: "100vh",
    overflowY: 'scroll'
  },
  badge: {
    cursor: 'pointer'
  },
  logo: {
    maxWidth: 384,
    width: "100%",
    height: "auto",
    minHeight: 200,
    marginTop: -100,
    marginBottom: -100
  },
  title: {
    fontWeight: "500",
    color: 'white',
    textShadow: "2px 2px 5px rgba(0,0,0,0.4)"
  },
  p: {
    color: 'white',
    maxWidth: 520,
    width: "100%",
    textAlign: 'center'
  }
}));

const Homepage = ({ projects }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickAdmin() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={classes.container}>
      <div>
        <img className={classes.logo} src={require("../../images/logo-large.png")} />
        {/* <Card className={classes.card}> */}
        <Typography className={classes.title} variant="h2">CrabGear</Typography>
          {/* <CardHeader title="CrabGear" /> */}
          <CardContent className={classes.p} variant="body">
          CrabGear supports supports the efforts of the West Coast Dungeness Crab Fishery to collect lost fishing gear. Recovery Captains in participating ports can use this mobile application to report where and when gear are recovered.
          </CardContent>
          <CardContent className={classes.p}>
            CrabGear is available for both iOS and Android
            <br />
          {/* </CardContent> */}
          {/* <CardContent> */}
            <a href="https://apps.apple.com/us/app/crabgear/id1459397439"><img className={classes.badge} src={AppStoreLogo} width={120} height={40} /></a>
            <a href="https://play.google.com/store/apps/details?id=org.tnc.crabgear"><img className={classes.badge} src={GooglePlayBadge} height={60} style={{marginBottom: -10}} /></a>
            <br />
            
          </CardContent>
        {/* </Card> */}
        <br />
        <Button
          variant="contained"
          // color="secondary"
          aria-haspopup="true"
          onClick={handleClickAdmin}
          className={classes.adminButton}
        >
          Port Coordinator Login
        </Button>
        <br />
        <br />
        <a style={{color: 'white'}} href="/tos.html">Terms of Service</a>
        <ProjectDialog open={open} onClose={handleClose} />
      </div>
    </div>
  );
};

export default Homepage;
