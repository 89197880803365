import { createMuiTheme } from '@material-ui/core/styles';
import Color from 'color';
import interpolate from 'color-interpolate';

const mobileGradientColors = ["#5ba3e1", "#4b5fcd"];
const colormap = interpolate(mobileGradientColors);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: colormap(0.1),
      main: colormap(0.5),
      dark: colormap(0.8),
    },
    secondary: {
      main: '#f54907',
    },
    activeNavigation: Color(colormap(0.4)).darken(0.2).hex()
  },
});

export default theme;