import React from 'react';
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getToken } from "./hooks/useAuthToken";

export const SERVER_URL = process.env.NODE_ENV === 'production' ? `https://z5qjl3ujbd.execute-api.us-west-2.amazonaws.com/production` : `http://${window.location.host.replace("3000", "4000")}`

const httpLink = createHttpLink({
  uri: SERVER_URL + "/graphql"
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const ConfiguredApolloProvider = ({children}) => <ApolloProvider client={client} children={children} />

export default ConfiguredApolloProvider;
export {
  client
};

