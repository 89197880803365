import React, { Suspense } from "react";
import Root from "./components/root";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FullScreenLoading from "./components/FullScreenLoading";
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
  Sentry.init({dsn: "https://2b1d158c18d349168800d88b4f699dab@sentry.io/1858762"});
}

const AuthPage = React.lazy(() => import("./components/auth/AuthPage"));
const ProjectApp = React.lazy(() => import("./ProjectApp"));
const isRootDomain = window.location.host.split(".")[0] === "crabgear";

const App = () => {
  return (
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            {isRootDomain ? (
              <Root />
            ) : (
              <Suspense fallback={<FullScreenLoading />}>
                <Switch>
                  <Route path="/auth/:state" component={AuthPage} />
                  <Route path="/" component={ProjectApp} />
                </Switch>
              </Suspense>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </MuiThemeProvider>
  );
};

export default App;
